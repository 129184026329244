import React, { Component } from 'react';
import TransitionContext from './TransitionContext';
import { withRouter } from 'react-router-dom';

let _timeoutID;
let _timeoutHistoryID;


class TransitionProvider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeLink: 0,
    };
  }

  setActiveLink = (index) => {
    this.setState({ activeLink: index });
  }

  transitionActiveLink = (e, index, url) => {
    e.preventDefault();
    e.target.blur();
    if (this.state.activeLink !== index || url !== window.location.pathname) {
      clearTimeout(_timeoutID);
      clearTimeout(_timeoutHistoryID);
      const main = document.getElementById('main').classList;

      //handles menu
      if (document.body.clientWidth < 700) {
        document.getElementById('nav-menu').classList.remove('active');
        _timeoutID = setTimeout(() => document.getElementById('loading-transition').classList.add('hidden'), 800);
      }

      //handles main fadeout
      main.add('fade-out');
      _timeoutHistoryID = setTimeout(() => {
        let transition = document.getElementById('loading-transition').classList;
        transition.add('active');

        _timeoutHistoryID = setTimeout(() => {
          window.scrollTo(0, 0);
          this.props.history.push(url);
          transition.remove('active');
          
          _timeoutHistoryID = setTimeout(() => {
            document.getElementById('main').classList.remove('fade-out');
          }, 300);

        }, 1000);

      }, 300);
    }
  }

  render() {
    const value = {
      ...this.state,
      setActiveLink: this.setActiveLink,
      transitionActiveLink: this.transitionActiveLink
    };

    return (
      <TransitionContext.Provider value={value}>
        {this.props.children}
      </TransitionContext.Provider>
    );
  }
}

export default withRouter(TransitionProvider);