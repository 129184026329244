const projects = {
  'squad-armory': {
    pathname: 'squad-armory',
    title: 'squad armory',
    image: 'squad-armory.png',
    main_image: 'squad-armory-main.png',
    sub_image: 'squad-armory-sub.png',
    subtitle: 'An app for gamers to find friends to play with in their favorite online multiplayer game',
    tags: ['HTML', 'CSS', 'React', 'Node', 'SocketIO', 'Express', 'PostgreSQL', 'Agile'],
    coauthors: ['Andrew Bituin', 'Jake Derhalli', 'William Wong'],
    repo_link: 'https://github.com/VarReach/The-Armory-Client',
    live_link: 'https://squad-armory.now.sh/',
    description: 'Finding friends to play online video games with is hard. Sometimes life gets in the way and schedules don\'t line up. Find your favorite game and join a squad that\'s looking exactly for whatever role you play. Can\'t find one that\'s right for you? Create your own squad! Chat with your new squadmates within the app and plan out your gaming session. Squad up and join Squad Armory!',
    responsibilites: [
      {title: 'Acting Product Manager', description: 'Represented the IDEA of the project. Kept the team focused on the product we were trying to deliver.'},
      {title: 'Squad List Page', description: 'Designed and implemented the totality of the squad list page. Including CSS and mobile responsiveness. Also includes backend SocketIO communication and RESTful endpoints. Set up to allow for instant updates when a squad is created or deleted. Page determines whether said update is relevant for each user based on their applied squad filters and page number.'},
      {title: 'Login/Registration Page', description: 'Designed and implemented the CSS of the login/registration page.'},
      {title: 'Dashboard', description: 'Designed and fixed up the CSS for the dashboard page. Also assisted with creating the RESTful endpoints for this page.'}
    ],
  },
  'chess-book': {
    pathname: 'chess-book',
    title: 'chess-book',
    image: 'chess-book.png',
    main_image: 'chess-book-main.png',
    sub_image: 'chess-book-sub.png',
    subtitle: 'A web-app developed to expand my skills and to host the eventual chessbook of my father. Title tentative.',
    tags: ['HTML', 'CSS', 'React', 'Node', 'Express', 'PostgreSQL'],
    coauthors: [],
    repo_link: 'https://github.com/VarReach/chess-book-client',
    live_link: 'https://varreach-chessbook-demo.now.sh/',
    description: 'Chess-Book can host multiple books, each of which contains chapters. Users can read through whichever book and chapter they wish. If they\'re logged in the app will mark said chapter as complete when they go to the next chapter. Admin(s) can access the editor which controls the content available on the app.',
    responsibilites: [],
  },
  'spaced-repetition': {
    pathname: 'spaced-repetition',
    title: 'spaced repetition',
    image: 'spaced-repetition.png',
    main_image: 'spaced-repetition-main.png',
    sub_image: 'spaced-repetition-sub.png',
    subtitle: 'Learn a new language through the spaced repetition technique.',
    tags: ['HTML', 'CSS', 'React', 'Node', 'Express', 'PostgreSQL'],
    coauthors: ['Chris Carnivale'],
    repo_link: 'https://github.com/VarReach/spaced-repetition-client',
    live_link: 'https://ac-spaced-repetition.now.sh/',
    description: 'This is a DuoLingo clone proof-of-concept that utilizes basic data structures and a responsive front end designed in React. Back-end supports multiple languages but only comes pre-seeded with 11 Dutch words. Project was completed to provided test specifications and built off of a provided project base.',
    responsibilites: [
      {title: 'Navigator', description: 'Acted as the navigator for the majority of pair programming. Dictated the code to be written, and explained why.'},
      {title: 'Design', description: 'Designed the look and feel of the project, implemented CSS.'},
    ],
  },
};

const EmptyProject = {
  pathname: '',
  title: '',
  main_image: '',
  sub_image: '',
  subtitle: '',
  tags: [],
  coauthors: [],
  repo_link: '',
  live_link: '',
  description: '',
  feature_list: [],
  responsibilites: [],
  notes: ''
};

export default projects;
export { EmptyProject };