import React from "react";
import { Link } from 'react-router-dom';
import  { EmptyProject } from '../../resources/store/projects';
import './Project.css';

function ProjectPreview(props) {
  const project = props.project;
  
  return (
    <li className={`project ${props.direction}`} id={project.pathname}>
      <div className="project-image-container">
        <img className="project-main-image" src={require(`../../resources/images/${project.image}`)} alt=""/>
      </div>
      <div className="project-details">
        <h2 className="project-title">{project.title}</h2>
        {project.tags.length > 0 && (
          <div className="project-list-container">
            <h3 className="project-list-title">Tech:</h3>
            <ul className="project-list">
              {project.tags.map(tag => <li key={tag}>{tag}</li>)}
            </ul>
          </div>
        )}
        <p className="project-description">{project.description}</p>
        {project.coauthors.length > 0 && (
          <div className="project-list-container">
            <h3 className="project-list-title">Co-Authors:</h3>
            <ul className="project-list">
              {project.coauthors.map(coauthor => <li key={coauthor}>{coauthor}</li>)}
            </ul>
          </div>
        )}
        <div className="project-link-container">
          <div className="project-link-holder">
            <a className="project-link" target="_blank" rel="noopener noreferrer" href={project.live_link}><i className="fas fa-external-link-alt"/> Visit the website</a>
          </div>
          <div className="project-link-holder">
            <a className="project-link" target="_blank" rel="noopener noreferrer" href={project.repo_link}><i className="fab fa-github-square"/> Check out the repo</a>
          </div>
        </div>
      </div>
    </li>
  );
}

ProjectPreview.defaultProps = {
  project: EmptyProject
};

export default ProjectPreview;
