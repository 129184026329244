import React, { useEffect, useContext } from 'react';
import './Landing.css';
import { Link } from 'react-router-dom';
import TransitionContext from '../../context/TransitionContext';
import AboutMePreview from '../../components/AboutMePreview/AboutMePreview';

export default function Landing(props) {
  const { setActiveLink, transitionActiveLink } = useContext(TransitionContext);

  useEffect(() => {
    setActiveLink(0);
  }, [setActiveLink]);

  return (
    <div>
      <AboutMePreview/>
      <div className="landing-links">
        <div className="landing-link-holder">
          <Link className="landing-link" to="/about" onClick={(e) => transitionActiveLink(e, 1, `/about`)}><i className="fa fa-user"/> About me</Link>
        </div>
        <div className="landing-link-holder">
          <Link className="landing-link" to="/portfolio" onClick={(e) => transitionActiveLink(e, 2, `/portfolio`)}><i className="fas fa-project-diagram"/> My projects</Link>
        </div>
      </div>
    </div>
);
}
