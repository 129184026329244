import React from "react";
import './AboutMePreview.css';

function AboutMePreview(props) {
  return (
    <div className="about-me-preview">
      <h1 className="about-me-title">Hello, <br/>I'm Alex,<br/>full-stack developer.</h1>
      <h2 className="about-me-subtitle">ReactJS / NodeJS / PostgreSQL</h2>
    </div>
  );
}

export default AboutMePreview;
