import React, { useContext, useRef, useEffect } from "react";
import { Link, withRouter } from 'react-router-dom';
import Logo from '../../resources/images/Logo.png';
import LogoRed from '../../resources/images/LogoRed.png';
import TransitionContext from '../../context/TransitionContext';
import './Header.css';

let _timeoutID;
let _timeoutHistoryID;

function Header(props) {
  const menu = useRef(null);
  const { transitionActiveLink, activeLink } = useContext(TransitionContext);

  useEffect(() => {
    return () => {
      clearTimeout(_timeoutHistoryID);
      clearTimeout(_timeoutID);
    };
  }, []);

  const generateListItems = () => {
    return (
      <ul id="nav-menu" className="nav-list hidden" ref={menu}>
        <li className="nav-list-item">
          <Link 
            className={activeLink === 1 ? "nav-link nav-link-1 active" : "nav-link nav-link-1"}
            to="/about" 
            onClick={(e) => transitionActiveLink(e, 1, '/about')}
          >
            about
          </Link>
        </li>
        <li className="nav-list-item">
          <Link 
            className={activeLink === 2 ? "nav-link nav-link-2 active" : "nav-link nav-link-1"}
            to="/portfolio" 
            onClick={(e) => transitionActiveLink(e, 2, '/portfolio')}
          >
            portfolio
          </Link>
        </li>
        <li className="nav-list-icon">
          <a className="nav-link-icon nav-link-3" href="mailto:derareich@gmail.com" aria-label="email">
            <i className="fas fa-envelope"/>
          </a>
        </li>
        <li className="nav-list-icon">
          <a className="nav-link-icon nav-link-4" target="_blank" rel="noopener noreferrer" href="https://github.com/VarReach" aria-label="github">
            <i className="fab fa-github"/>
          </a>
        </li>
        <li className="nav-list-icon">
          <a className="nav-link-icon nav-link-5" target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/in/alex-n-reich/" aria-label="linked-in">
            <i className="fab fa-linkedin-in"/>
          </a>
        </li>
      </ul>
    )
  }
  
  const showMenu = () => {
    const bool = menu.current.classList.contains('active');
    clearTimeout(_timeoutID);
    if (bool) {
      menu.current.classList.toggle('active');
      _timeoutID = setTimeout(() => menu.current.classList.add('hidden'), 600);
    } else {
      menu.current.classList.remove('hidden');
      _timeoutID = setTimeout(() => menu.current.classList.add('active'), 50);
    }
  }

  return (
    <header id="header" className="header">
      <Link id="logo" 
        className={activeLink === 0 ? "logo active" : "logo"} 
        to="" 
        onClick={(e) => transitionActiveLink(e, 0, '')}
      >
        <div className="logo-images">
          <img className="logo-image" src={Logo} alt=""/>
          <div className="logo-image-red-container">
            <img className="logo-image-red" src={LogoRed} alt=""/>
          </div>
        </div>
        <div className="logo-text">
          <span className="logo-name">Alex Reich</span>
          <span className="logo-title">Full-Stack Developer</span>
        </div>
      </Link>
      <nav role="navigation" className="nav">
        {generateListItems()}
        <button className="nav-button" onClick={showMenu} aria-label="menu">
          <i className="fas fa-bars"/>
        </button>
      </nav>
    </header>
  );
}

export default withRouter(Header);
