import React, { useEffect, useContext } from "react";
import { Redirect } from 'react-router-dom';
import TransitionContext from '../../context/TransitionContext';
import './FourOhFour.css';

function FourOhFour(props) {
  const { setActiveLink } = useContext(TransitionContext);

  useEffect(() => {
    setActiveLink(-1);
  }, [setActiveLink]);

  return (
    <div className="about-me-preview">
      <h1 className="four-oh-four-title about-me-title">404 - Page Not Found</h1>
      <Redirect to=""/>
    </div>
  );
}

export default FourOhFour;
