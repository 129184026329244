import React, { useContext } from "react";
import { Link } from 'react-router-dom';
import TransitionContext from '../../context/TransitionContext';
import './Footer.css';

function Footer(props) {
  const { transitionActiveLink } = useContext(TransitionContext);

  return (
    <footer role="contentinfo" className="footer">
      <ul className="footer-section">
        <li>
          <Link to="" onClick={(e) => transitionActiveLink(e, 0, '')}>
            Home
          </Link>
        </li>
        <li>
          <Link to="/about" onClick={(e) => transitionActiveLink(e, 1, '/about')}>            
            About
          </Link>
        </li>
        <li>
          <Link to="/portfolio" onClick={(e) => transitionActiveLink(e, 2, '/portfolio')}>
            Portfolio
          </Link>
        </li>
      </ul>
      <div className="footer-decoration"/>
    </footer>
  );
}

export default Footer;
