import React, { useEffect, useContext } from "react";
import TransitionContext from '../../context/TransitionContext';
import './AboutMe.css';

function AboutMe(props) {
  const { setActiveLink } = useContext(TransitionContext);

  useEffect(() => {
    setActiveLink(1);
  }, [setActiveLink]);

  return (
    <div className="about-me-preview">
      <h1 className="about-me-title">about me</h1>
      <h2 className="about-me-sub about-me-subtitle">I'm a full-stack developer with a penchant for UI/UX.</h2>
      <p className="about-me-p">
        I adore challenges. I delight in enigmas. So, I guess you could say that I relish challenging puzzles. It's for this reason that I love programming. 
      </p>
      <p className="about-me-p">
        Before my coding adventures, I studied actuarial science and finance. 
        I even graduated with a degree joint-majoring in both of those! 
        I leverage the skills I ganished back then to work on the backend, and I use my creative side to work on the frontend. 
      </p>
      <p className="about-me-p">
        If I'm not working, you can find me playing video games (I'm pretty good at FPSs), watching or reading Sci-Fi (bring back Stargate!), or enjoying a nice can of LaCroix. 
      </p>
    </div>
  );
}

export default AboutMe;
