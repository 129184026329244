import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import Portfolio from './routes/Portfolio/Portfolio';
import Landing from './routes/Landing/Landing';
import AboutMe from './routes/AboutMe/AboutMe';
import FourOhFour from './routes/FourOhFour/FourOhFour';

import './App.css';

function App() {
  return (
    <>
      <Header />
      <div id="loading-transition" className="loading-transition" />
      <main id="main">
        <Switch>
          <Route
            exact
            path="/"
            component={Landing}
          />
          <Route
            path="/portfolio"
            component={Portfolio}
          />
          <Route
            path="/about"
            component={AboutMe}
          />
          <Route
           component={FourOhFour}
          />
        </Switch>
      </main>
      <Footer />
    </>
  );
}

export default App;
