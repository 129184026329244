import React, { useEffect, useContext } from 'react';
import TransitionContext from '../../context/TransitionContext';
import Project from '../../components/Project/Project';
import ProjectStore from '../../resources/store/projects';
import './Portfolio.css';

export default function Portfolio(props) {
  const { setActiveLink } = useContext(TransitionContext);

  useEffect(() => {
    setActiveLink(2);
  }, [setActiveLink]);

  function generatePreviews() {
    return Object.values(ProjectStore).map((project, i) => {
      return (i % 2 === 0)
        ? <Project key={i} project={project} direction="left"/>
        : <Project key={i} project={project} direction="right"/>
    });
  }

  return (
    <div className="portfolio-container">
      <div className="portfolio-title-container about-me-preview">
        <h1 className="about-me-title">portfolio</h1>
        <h2 className="about-me-subtitle">Check out some of my latest web development projects</h2>
      </div>
      <ul className="projects-list">
        {generatePreviews()}
      </ul>
    </div>
);
}
